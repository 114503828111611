import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton"; // Import Skeleton
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton styles

const SubCategoryListing = () => {
  const { id } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const navigate = useNavigate();

  // Fetch subcategories by category ID
  const getSubCategoriesByCategoryId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/subategory/category/${id}`
      );
      console.log("sub", response.data);
      setSubCategories(response.data.data);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setLoading(false); // Set loading to false if an error occurs
    }
  };

  useEffect(() => {
    if (id) {
      getSubCategoriesByCategoryId();
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [id]);

  const handleSubcategoryProduct = (subCategoryId) => {
    navigate(`/subcategory/products/${subCategoryId}`);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="flex justify-center text-2xl font-bold mb-4">
        Subcategories
      </h1>
      {loading ? (
        // Render skeleton loader while loading
        <div className="grid sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="border rounded-lg shadow-md sm:w-[80px] sm:h-[150px] lg:w-[200px] lg:h-[250px]"
            >
              <Skeleton height={200} width={180} className="rounded-lg" />
              <div className="flex justify-center items-center mt-4">
                <Skeleton width={180} height={20} />
              </div>
            </div>
          ))}
        </div>
      ) : subCategories.length > 0 ? (
        <div className="grid sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {subCategories.map((subCategory) => (
            <div
              key={subCategory.id}
              onClick={() => handleSubcategoryProduct(subCategory.id)}
              className="border rounded-lg shadow-md sm:w-[80px] sm:h-[150px] lg:w-[200px] lg:h-[250px]"
            >
              <Link
                to={`/subcategory/products/${subCategory.id}`}
                className="relative block w-full h-full"
              >
                <img
                  src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/categories/subcategories/${subCategory.image}`}
                  alt={subCategory.sub_category_name}
                  className="w-full h-[220px] object-cover rounded-lg"
                />
                <p className="flex justify-center text-sm font-medium text-gray-900 bottom-4 bg-white rounded-md">
                  {subCategory.sub_category_name}
                </p>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No subcategories available for this category.</p>
      )}
    </div>
  );
};

export default SubCategoryListing;
