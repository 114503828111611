import React from "react";
import { FaSearch } from "react-icons/fa";

const NavBarSearchMobile = () => {
  return (
    <div className="sticky top-24 max-sm:top-14 w-full bg-white border border-blue-100 lg:hidden ">
      <div className="flex items-center justify-between px-4 border border-2 m-2 rounded-md">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search products..."
          className="w-full bg-white text-black px-4 py-2 rounded-lg focus:outline-none"
        />

        {/* Search Button */}
        <button className="text-purple-500 ml-2">
          <FaSearch size={20} />
        </button>
      </div>
    </div>
  );
};

export default NavBarSearchMobile;
