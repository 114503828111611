import axios from "axios";
import { useEffect, useState } from "react";
import { BiBell } from "react-icons/bi";
import { LuCalendarDays } from "react-icons/lu";
import { MdOutlineMenu } from "react-icons/md";
import avtar from "../../images/profiledummy.png";
import UserAccount from "./UserAccount";

const Teams = () => {
  const [activeNav, setActiveNav] = useState("Direct Referral");
  const [activePage, setActivePage] = useState(1); // Tracks the currently active page
  const [directRefral, setDirectRefral] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filter, setFilter] = useState("All"); // Tracks the filter: All, Active, Inactive
  const userId = localStorage.getItem("userId");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [requestType, setRequestType] = useState("7 Days"); // Tracks selected request type
  const [dateTab, setDateTab] = useState("Today"); // Tracks selected date tab
  const [selectedDates, setSelectedDates] = useState([16, 17, 18]); // Tracks selected calendar dates
  const [selectedTab, setSelectedTab] = useState("Today"); // Default selected tab
  // const [day, setDay] = useState(false);
  const [userAccount, setUserAccount] = useState(true);

  const isSelected = (day) => selectedDates.includes(day); // Checks if a date is selected

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const fetchDirectRefral = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}refferal/user/${userId}/directReferral`
      );
      setDirectRefral(response.data.data);
      console.log("dr", response.data.data);

      const responseTeam = await axios.get(
        `${process.env.REACT_APP_NODE}refferal/user/${userId}/team`
      );
      setTeams(responseTeam.data.data);
      console.log("Tr", responseTeam.data.data);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  useEffect(() => {
    fetchDirectRefral();
  }, []);

  // Filtered data for Direct Referral
  const filteredDirectReferral = directRefral.filter((card) => {
    if (filter === "All") return true;
    return filter === "Active" ? card.status === 1 : card.status !== 1;
  });

  // Filtered data for Team Referral
  const filteredTeams = teams.filter((card) => {
    if (filter === "All") return true;
    return filter === "Active" ? card.status === 1 : card.status !== 1;
  });
  const toggleUserAccount = () => {
    setUserAccount(!userAccount);
  };
  return (
    <div className="flex gap-4">
      <div>{userAccount ? <UserAccount /> : null}</div>
      <div className="bg-white pb-8 max-w-[1280px] w-full mr-2">
        <div className="flex justify-between items-center max-sm:flex-row-reverse p-2 mr-2 bg-white">
          <div className="w-12 border">
            <MdOutlineMenu
              className="border px-1 rounded-md m-2 text-4xl cursor-pointer"
              onClick={toggleUserAccount}
            />
          </div>
          <div
            className="flex justify-evenly items-center gap-2 md:gap-4 relative mr-2 max-md:w-2/3 max-sm:w-full max-sm:justify:end max-sm:ml-10 max-lg:w-1/2"
            style={{
              zIndex: 10, // Set the z-index value
              position: "relative", // Ensure the element's position property allows z-index to take effect
            }}
          >
            {/* Profile Container */}
            <div className="flex justify-start items-center border border-gray-400 rounded-lg max-sm:w-52 md:w-64 p-2 bg-[#FFF6FF]">
              <span className="mr-4">
                <img
                  src={avtar}
                  alt="user"
                  className="w-12 h-12 rounded-full"
                />
              </span>
              <div>
                <div className="name font-semibold">Abhishek Kumar</div>
                <div className="level text-gray-500 text-sm">Level 10</div>
              </div>
            </div>
            {/* Calendar Container */}
            {isCalendarOpen ? (
              <div className="bg-white ">
                <div className="w-[400px] bg-white rounded-lg shadow-md p-6 right-0 absolute    mt-12">
                  {/* Header */}
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Date Filter</h2>
                    <button
                      className="text-gray-500 hover:text-gray-800 text-3xl bg-gray-200 px-2  rounded-xl "
                      onClick={toggleCalendar}
                    >
                      &times;
                    </button>
                  </div>

                  {/* Request Type */}
                  <div>
                    <p className="text-gray-600 text-xs font-semibold m-2">
                      Request Type
                    </p>
                    <div className="flex gap-4 mb-6">
                      <div className="flex flex-nowrap justify-start border py-2 px-3 rounded-md shadow-md">
                        <input
                          type="radio"
                          name="filter"
                          className="mr-1 scale-125"
                        />
                        <span>7 Days</span>
                      </div>
                      <div className="flex flex-nowrap border py-2 px-3 rounded-md shadow-md">
                        <input
                          type="radio"
                          name="filter"
                          className="mr-1 scale-125"
                        />
                        This month
                      </div>
                      <div className="flex flex-nowrap border py-2 px-3 rounded-md shadow-md">
                        <input
                          type="radio"
                          name="filter"
                          className="mr-1 scale-125"
                        />
                        Lifetime
                      </div>
                    </div>
                  </div>

                  {/* Date Tabs */}
                  <div className="flex gap-4 mb-6">
                    <div className="w-96 m-auto border bg-gray-300 rounded-full flex justify-between">
                      {/* Today Button */}
                      <button
                        onClick={() => setSelectedTab("Today")}
                        className={`w-1/2 p-2 rounded-full ${
                          selectedTab === "Today"
                            ? "bg-[#3F8CFF] text-white"
                            : "bg-gray-300"
                        }`}
                      >
                        Today
                      </button>

                      {/* Yesterday Button */}
                      <button
                        onClick={() => setSelectedTab("Yesterday")}
                        className={`w-1/2 p-2 rounded-full ${
                          selectedTab === "Yesterday"
                            ? "bg-[#3F8CFF] text-white"
                            : "bg-gray-300"
                        }`}
                      >
                        Yesterday
                      </button>
                    </div>
                  </div>

                  {/* Calendar */}
                  <div className="border rounded-lg p-4">
                    {/* Calendar Header */}
                    <div className="flex justify-between items-center mb-4">
                      <button className="text-blue-500">&larr;</button>
                      <span className="font-semibold">September, 2024</span>
                      <button className="text-blue-500">&rarr;</button>
                    </div>

                    {/* Weekdays */}
                    <div className="grid grid-cols-7 text-center text-gray-500 mb-2">
                      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                        (day) => (
                          <span key={day} className="font-medium">
                            {day}
                          </span>
                        )
                      )}
                    </div>

                    {/* Days */}
                    <div className="grid grid-cols-7 text-center">
                      {/* Empty slots for alignment */}
                      {[...Array(1)].map((_, i) => (
                        <span key={i}></span>
                      ))}

                      {/* Days in the month */}
                      {Array.from({ length: 30 }, (_, i) => i + 1).map(
                        (day) => (
                          <button
                            key={day}
                            onClick={() =>
                              setSelectedDates((prev) =>
                                prev.includes(day)
                                  ? prev.filter((d) => d !== day)
                                  : [...prev, day]
                              )
                            }
                            className={`w-10 h-10 rounded-full ${
                              isSelected(day)
                                ? "bg-blue-500 text-white"
                                : "hover:bg-gray-200 text-gray-800"
                            }`}
                          >
                            {day}
                          </button>
                        )
                      )}
                    </div>
                  </div>

                  {/* Apply Button */}
                  <div className="flex justify-end mt-6">
                    <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {/* Notification Bell */}

            <span
              className="p-2 bg-gray-300 rounded-full cursor-pointer "
              onClick={toggleCalendar}
            >
              <LuCalendarDays className="text-xl" />
            </span>
            <BiBell className="text-2xl cursor-pointer" />
          </div>
        </div>
        <hr />
        <div className="my-2">
          <div className="flex justify-end items-center max-sm:gap-4 max-sm:flex-col-reverse max-sm:my-4 max-w-[1280px] m-auto gap-20">
            {/* Navigation buttons */}
            <nav className="flex gap-4 justify-evenly items-center bg-gray-300 w-[40%] rounded-full text-black lg:max-w-96 max-sm:w-72 max-sm:gap-0">
              <button
                onClick={() => setActiveNav("Direct Referral")}
                className={`px-4 py-2 rounded-full w-48 ${
                  activeNav === "Direct Referral"
                    ? "bg-blue-500 text-white"
                    : ""
                }`}
              >
                Direct Referral
              </button>
              <button
                onClick={() => setActiveNav("Team Referral")}
                className={`px-4 py-2 rounded-full w-48 ${
                  activeNav === "Team Referral" ? "bg-blue-500 text-white" : ""
                }`}
              >
                Team Referral
              </button>
            </nav>

            {/* main below 2 radio and progress */}
            <div className="flex justify-between items-center">
              {/* Radio buttons */}
              <div className="flex justify-between w-64">
                <span>
                  <label>All</label>
                  <input
                    type="radio"
                    name="filterReferral"
                    className="m-2 scale-125"
                    value="All"
                    checked={filter === "All"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </span>
                <span>
                  <label>Active</label>
                  <input
                    type="radio"
                    name="filterReferral"
                    className="m-2 scale-125"
                    value="Active"
                    checked={filter === "Active"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </span>
                <span>
                  <label>Inactive</label>
                  <input
                    type="radio"
                    name="filterReferral"
                    className="m-2 scale-125"
                    value="Inactive"
                    checked={filter === "Inactive"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </span>
              </div>
              {/* progress bar  */}
              <div className="w-52  max-sm:w-20">
                {activeNav === "Direct Referral" ? (
                  <h1 className="text-2xl font-semibold rounded-full p-3 max-sm:font-normal max-sm:w-10 max-sm:h-10 border-[#15C0E6] border-2 border-r-2 border-r-lightgray w-16 h-16 flex justify-center items-center mx-auto">
                    {filteredDirectReferral.length}
                  </h1>
                ) : activeNav === "Team Referral" ? (
                  <h1 className="text-2xl font-semibold rounded-full p-3 max-sm:font-normal max-sm:w-10 max-sm:h-10 border-[#15C0E6] border-2 border-r-2 border-r-lightgray w-16 h-16 flex justify-center items-center mx-auto">
                    {filteredTeams.length}
                  </h1>
                ) : null}
              </div>
            </div>
          </div>

          {/* Direct Referral */}
          {activeNav === "Direct Referral" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-[1280px] m-auto">
              {filteredDirectReferral.map((card) => (
                <div
                  key={card.id}
                  className="shadow-slate-50 border drop-shadow-md p-4 rounded-xl"
                >
                  <div
                    className="rounded-2xl h-50 flex flex-col items-center justify-evenly p-2"
                    style={{ backgroundColor: card.bgColor }}
                  >
                    <span className="w-20 h-20 rounded-full border-4 border-[#FFF7E5] flex justify-center items-center">
                      <img
                        src={avtar}
                        alt="user"
                        className="w-16 h-16 rounded-full"
                      />
                    </span>
                    <p className="font-semibold">{card.userName}</p>
                    <p>Level: {card.level}</p>
                    <button
                      className={`border border-1 ${
                        card.status === 1
                          ? "border-green-500 text-green-500"
                          : "border-red-500 text-red-500"
                      } text-sm px-2 rounded-md`}
                    >
                      {card.status === 1 ? "Active" : "Inactive"}
                    </button>
                  </div>
                  <div className="direct-teams flex justify-evenly items-center w-full mt-5">
                    <div className="flex flex-col items-center">
                      <span className="text-xl font-bold">
                        {card.directRefMembers}
                      </span>
                      <span>Direct</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-xl font-bold">{card.teams}</span>
                      <span>Teams</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-xl font-bold">
                        {card.totalUser}
                      </span>
                      <span>Total</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Team Referral */}
          {activeNav === "Team Referral" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-[1280px] m-auto">
              {filteredTeams.map((card) => (
                <div
                  key={card.id}
                  className="shadow-slate-50 border drop-shadow-md p-4 rounded-xl max-sm:max-w-80 m-4"
                >
                  <div
                    className="rounded-2xl h-64 flex flex-col items-center justify-evenly"
                    style={{ backgroundColor: card.bgColor }}
                  >
                    <span className="w-20 h-20 rounded-full border-4 ${card.borderColor} flex justify-center items-center">
                      <img
                        src={avtar}
                        alt="user"
                        className="w-16 h-16 rounded-full"
                      />
                    </span>
                    <p>Team Name: {card.teamName}</p>
                  </div>

                  <div className="direct-teams flex justify-evenly items-center w-full">
                    <div className="flex flex-col items-center">
                      <span className="text-xl font-bold">{card.coins}</span>
                      <span className=" font-bold">Coins</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-xl font-bold">
                        {card.team.length}
                      </span>
                      <span className=" font-bold">Teams</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Teams;
