import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { IoMdHeartEmpty } from "react-icons/io";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddReview from "../components/AddReview";
import ProductCard from "../components/ProductCard";

const ProductDetail = () => {
  const navigate = useNavigate();
  var { id } = useParams();
  id = parseInt(id);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [activeTab, setActiveTab] = useState("description");
  const [loading, setLoading] = useState(true);
  const [first, setFirst] = useState([]);
  // const [selectedPrice,setselectedPrice]=useState({});
  const [selectedColor, setSelectedColor] = useState(""); // Track the selected color
  const [cartImage, setCartImage] = useState([]);
  const [description, setDescription] = useState("");

  // console.log("cartImage is", typeof cartImage);
  const [productName, setProductName] = useState("");
  const [mainproduct, setProduct] = useState({});
  const [mainPrice, setMainPrice] = useState([]);
  const [saleprice, setSalePrices] = useState("");
  const [oldPrice, setOldPrices] = useState("");
  const [images, setImages] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [sizeIndex, setSizeIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);
  const [coin, setCoin] = useState(0);

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const sanitizedDescription = DOMPurify.sanitize(description);
  const Description = DOMPurify.sanitize(description.slice(0, 800));

  useEffect(() => {
    async function getProduct() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}getSignleProduct/product_id_${id}`
        );
        console.log(response.data.products[0]);
        setProduct(response.data.products[0]);
        setProductName(response.data.products[0].name);
        setCoin(response.data.products[0].coin);
        setDescription(response.data.products[0].description);
        console.log("setproduct is ", response.data.products[0].name);
        setMainPrice(response.data.products[0].prices);
        setSalePrices(
          response.data.products[0].prices[0].configurations[0].sale_price
        );
        setOldPrices(
          response.data.products[0].prices[0].configurations[0].old_price
        );
        setImages(response.data.products[0].prices[0].images);
        setMainImage(
          response.data.products[0].prices[0].images[0].image_path || ""
        );
        setConfigurations(response.data.products[0].prices[0].configurations);

        const defaultColor = response.data.products[0].prices[0].color_name;
        setSelectedColor(defaultColor);
        console.log("Default color selected:", defaultColor);
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    }
    getProduct();
    fetchFirstCampaign();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [id]);

  const fetchFirstCampaign = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/campaign/page/product/location/1`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setFirst(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 1", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };

  if (!mainproduct) return <div>Product not found</div>;

  const handleAddToCart = async (productId, venderId) => {
    try {
      const configuratioId = configurations[sizeIndex].id;
      // console.log("Configuration ID is ", configuratioId);
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/add-product/${userId}/${productId}`,
        {
          quantity: 1,
          configuration_id: configuratioId,
          color: selectedColor,
        }
      );
      console.log("product added to cart: ", response);
      if (response.status == 200) {
        // dispatch(addToCart([product], totalQuantity + 1));
        // alert("Added in cart Sucessfully")
        toast.success("Added in Cart Successfully!");
        navigate("/cart");
      }
    } catch (err) {
      console.log("product not found: ", productId);
    }
  };

  const handleBuyNow = async (productId, venderId) => {
    const dataa = {
      user_id: userId,
      color: selectedColor,
      products: productId,
      size: configurations[sizeIndex].size,
      product_configuration_id: configurations[sizeIndex].id,
      old_price: oldPrice,
      price: saleprice,
      quantity: 1,
      image_url: mainImage,
      product_name: productName,
      coin: coin,
    };
    console.log("buying data is is", dataa);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}buyorders`,
        dataa
      );
      console.log("response", response.data);
      const buyId = response.data.id;
      console.log("buy", response.data);
      // alert("Product buying!");
      toast.success("Product Buying!");
      navigate(`/buySummary/${buyId}`);
    } catch (error) {
      console.error("Error in Buying Product", error);
    }
  };

  const handleWishlist = async (productId, venderId) => {
    const data = {
      color: selectedColor,
      size: "L",
      configurations: configurations,
      old_price: oldPrice,
      sale_price: saleprice,
    };
    console.log("the data Wishlist  is ", data);
    try {
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}wishlist/user_id_${userId}/vendor_id_${venderId}/product_id_${productId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // alert("Product added to wishlist!");
      toast.success("Product added to wishlist!");
    } catch (error) {
      console.error("Error in addWishlist", error);
      // alert("Select the color of item to add in Wishlist!");
      toast.error("Select the color of item to add in Wishlist!");
    }
  };

  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-yellow-400" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-yellow-400" />;
      } else {
        return <FaRegStar key={index} className="text-yellow-400" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };

  function setDetailsProduct(index) {
    setSalePrices(configurations[index].sale_price);
  }

  function setProductDetails(value) {
    const newObje = mainPrice.find((price) => price.color_name === value);
    console.log(newObje.images[0], value);
    setSizeIndex(0);
    setImages(newObje.images);
    setMainImage(newObje.images[0].image_path);
    setSalePrices(newObje.configurations[0].sale_price);
    setConfigurations(newObje.configurations);
    setSelectedColor(value);

    // setCartImage(selectedPrice.images[0].image_path);
  }
  // Find the selected price based on the selected color

  // Set default image
  return (
    <div className="">
      <div className="flex flex-col relative ml-6 mr-6">
        {loading ? (
          // Render Skeleton Loader
          <div className="flex flex-col md:flex-row rounded-lg overflow-hidden relative">
            {/* Left Section */}
            <div className="w-full sticky top-10 h-[700px] md:w-1/2 sm:p-8">
              <Skeleton width={630} height={500} className="rounded-lg" />
              <div className="flex overflow-x-auto mt-2 space-x-2">
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    key={index}
                    width={120}
                    height={130}
                    className="rounded-lg"
                  />
                ))}
              </div>
            </div>

            {/* Right Section */}
            <div className="w-full md:w-1/2 lg:p-4 flex flex-col sm:pt-10">
              <Skeleton width="50%" height={24} className="mb-4" />
              <Skeleton width="50%" height={24} className="mb-4" />
              <Skeleton width={80} height={20} className="mt-4" />
              <Skeleton width="33%" height={24} className="mb-2" />
              <Skeleton width="100%" height={48} className="mb-4" />
              <Skeleton width="25%" height={16} className="mr-2" />
              <Skeleton width="33%" height={16} />
              <div className="flex flex-col border-b-2 space-y-6 pb-4">
                <Skeleton width={64} height={16} />
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    width={48}
                    height={24}
                    className="rounded-md"
                  />
                ))}
              </div>
              <Skeleton width={112} height={32} className="rounded-full mt-6" />
              <Skeleton width={112} height={32} className="rounded-full" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row  rounded-lg overflow-hidden relative">
            <div className="w-full sticky top-10 h-[700px] md:w-1/2 sm:p-8">
              <img
                src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${mainImage}`}
                // src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                alt={mainproduct.name}
                className="w-[630px] h-[500px] object-cover rounded-lg border-2 border-gray"
              />
              <div className="flex overflow-x-auto mt-2 space-x-2">
                {/* Enable horizontal scrolling and add spacing */}
                {images.map((image, imageIndex) => (
                  <img
                    key={imageIndex}
                    src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${image.image_path}`}
                    alt={mainproduct.name}
                    onClick={() => setMainImage(image.image_path)}
                    className="w-[120px] h-[130px] object-cover rounded-lg border-2 border-gray hover:border-purple-500 cursor-pointer border" // Add cursor pointer for better UX
                  />
                ))}
              </div>
            </div>

            <div className="w-full md:w-1/2  lg:p-4 flex flex-col sm:pt-10 ">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-xl md:text-2xl font-bold mt-5 lg:pb-2  ">
                    {mainproduct.name}
                  </h2>
                </div>
                {/* <div
                  onClick={() =>
                    handleWishlist(mainproduct.id, mainproduct.vendor_id)
                  }
                  className="text-[24px]  md:text-[30px] text-red-500"
                >
                  <IoMdHeartEmpty />
                </div> */}
              </div>

              <div className="flex space-x-2 md:space-x-4 items-center h-[40px] md:h-[50px]">
                <p className="border-r-2 border-gray-300 pr-2 md:pr-4">
                  {renderStars(mainproduct.rating)}
                </p>
                <a
                  href="#productReview"
                  className="text-blue-500 text-sm md:text-base cursor-pointer"
                >
                  Add Review
                </a>
              </div>

              <p className="text-lg md:text-xl font-semibold mb-2 md:mb-2">
                MRP: ₹{saleprice}
              </p>
              <p className="font-semibold text-lg text-orange-500">
                Coins: {mainproduct.coin}
              </p>

              <p
                className="text-gray-500 mb-4"
                dangerouslySetInnerHTML={{ __html: Description }}
              ></p>

              {/* <div className="flex items-center pb-2 sm:pb-1 mb-4">
                <p className="font-semibold">Categories: </p>
                <p className="text-gray-500">categories</p>
              </div> */}

              <div className="flex flex-col border-b-2  space-y-6">
                <div className="flex items-center gap-4 ">
                  <p className="font-semibold text-lg ">Color:</p>
                  {mainPrice.map((price, index) => (
                    <div key={index} className="flex items-center">
                      <button
                        onClick={() => {
                          setProductDetails(price.color_name);
                          setColorIndex(index);
                        }} // Set selected color
                        className={`${
                          colorIndex === index
                            ? "border-purple-600"
                            : "border-gray-400"
                        } border px-2 py-1 rounded-md cursor-pointer`}
                      >
                        {price.color_name}
                      </button>
                    </div>
                  ))}
                  {selectedColor === null && (
                    <p className="mt-4 text-red-600 text-sm text-semibold">
                      Please select a color and Size before Buying.
                    </p>
                  )}
                </div>

                <div className="flex  border-b-2 space-y-6 ">
                  <div className="flex items-center gap-4 mb-5 ">
                    <p className="font-semibold  text-lg">Size:</p>
                    {configurations.map((config, configIndex) => (
                      <div key={configIndex} className="flex  items-center ">
                        <button
                          type="text"
                          value={config.size}
                          readOnly
                          className={`${
                            sizeIndex === configIndex
                              ? "border-purple-600"
                              : "border-gray-400"
                          } border px-2 py-1 rounded-md cursor-pointer`}
                          onClick={() => {
                            setSizeIndex(configIndex);
                            setDetailsProduct(configIndex);
                          }}
                        >
                          {config.size}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="hidden md:flex items-center h-[40px] md:h-[50px] mt-6 space-x-2 md:space-x-4 flex-wrap">
                <Link to={`/buySummary/${mainproduct.id}`}>
                  <button
                    onClick={() =>
                      handleBuyNow(mainproduct.id, mainproduct.vendor_id)
                    }
                    className="bg-purple-500 text-white px-4 py-1 rounded-full hover:bg-purple-700 transition-colors sm:px-5 sm:py-2"
                  >
                    Buy Now
                  </button>
                </Link>

                <button
                  disabled={loading}
                  onClick={() =>
                    handleAddToCart(mainproduct.id, mainproduct.vendor_id)
                  }
                  className="bg-purple-500 text-white px-4 py-1 rounded-full hover:bg-purple-700 transition-colors sm:px-5 sm:py-2"
                >
                  {loading ? "Adding..." : "Add To Cart"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <div className="flex flex-col  bg-gray-100 rounded-xl">
          <div className="ml-10 mb-5">
            <div className="mt-5 font-bold">
              <h2>Reviews Summary by AI:</h2>
            </div>

            <div className="flex flex-col mt-2 mr-4">
              <h2 className="font-bold">Summary:</h2>
              <p className="text-gray-500">
                This chair is attractive and easy to set up but it may be too
                stiff for some people's comfort...
              </p>
            </div>

            <div className="flex flex-col mt-5">
              <h2 className="font-bold">Pros:</h2>
              <ul className="text-gray-500">
                <li>Attractive</li>
                <li>Easy to set up</li>
                <li>Great Quality</li>
                <li>Enhancer for any Space</li>
              </ul>
            </div>

            <div className="flex flex-col mt-5">
              <h2 className="font-bold">Cons:</h2>
              <ul className="text-gray-500">
                <li>Stiff</li>
                <li>Lightweight Arm Cushions</li>
                <li>Troubles with Woodpanel Screws</li>
              </ul>
            </div>
          </div>
        </div> */}
        <div className="lg:m-4">
          <div className="flex space-x-4 mb-5 border-b-2 lg:mt-4">
            <button
              className={`py-2 px-4 ${
                activeTab === "description"
                  ? "border-black border-b-2 font-semibold"
                  : ""
              }`}
              onClick={() => setActiveTab("description")}
            >
              Description
            </button>

            <button
              className={`py-2 px-4 ${
                activeTab === "details"
                  ? "border-black border-b-2 font-semibold"
                  : ""
              }`}
              onClick={() => setActiveTab("details")}
            >
              Details
            </button>
          </div>

          <div>
            {activeTab === "description" && (
              <div className="m-4">
                <h3 className="font-bold">Product Description</h3>
                <p
                  className="text-black-500 mb-4"
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                ></p>
              </div>
            )}
            {activeTab === "details" && (
              <div className="m-4">
                <h3 className="font-bold">Product Details</h3>
                <p>Dimensions: {mainproduct.dimensions}</p>
                <p>Weight: {mainproduct.weight}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1480px] h-[250px] bg-white-200 lg:mx-5 shadow-lg">
        <img
          src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${first.image}`}
          alt=""
          className="w-full h-full object-cover p-4"
        />
      </div>

      <AddReview />
      <ProductCard />

      {/* for mobile view */}
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-2 md:hidden flex justify-between items-center">
        <button
          className="bg-purple-500 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition-colors sm:px-10 sm:py-2"
          onClick={() => handleBuyNow(mainproduct.id, mainproduct.vendor_id)}
        >
          Buy Now
        </button>
        <button
          disabled={loading}
          onClick={() => handleAddToCart(mainproduct.id, mainproduct.vendor_id)}
          className="bg-purple-500 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition-colors sm:px-10 sm:py-2"
        >
          {loading ? "Adding..." : "Add To Cart"}
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
