import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaSearch, FaUser } from "react-icons/fa";
import {
  FiHeart,
  FiLogOut,
  FiPackage,
  FiShoppingCart,
  FiUser,
} from "react-icons/fi";
import { IoCartSharp, IoWallet } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import "./NavbarButton.css";

import { FaShop } from "react-icons/fa6";
import { GiTwoCoins } from "react-icons/gi";
import { IoIosAppstore, IoLogoAndroid } from "react-icons/io";
import { MdInstallMobile } from "react-icons/md";
import { useSelector } from "react-redux";
import { MyContext } from "../../context/MyContext";
const Navbar = () => {
  const {
    search,
    setSearch,

    getSearchItem,
  } = useContext(MyContext);
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [serchItem, setSearchItem] = useState("");

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const totalQuantity = useSelector((state) => state.totalQuantity);

  useEffect(() => {
    getSearchItem(serchItem);
  }, [serchItem]);
  console.log(serchItem);

  const fetchCartItems = async () => {
    setLoading(true);
    setError(null);

    if (!token || !userId) {
      setError("You need to log in to view the cart.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token to authenticate the request
          },
        }
      );
      console.log(response.data);
      let products = response.data.cart.products;
      setCartItems(products);
      let sum = 0;
      for (let i = 0; i < products.length; i++) {
        sum += parseInt(products[i].pivot.price * products[i].pivot.quantity);
      }
      // console.log("total price ", totalPrice, sum);
      setTotalPrice(sum);
      // console.log("carts items", response.data.cart.products);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("Failed to load cart items.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCartItems();
  }, []);
  console.log("cartitems", cartItems);
  return (
    // bg-[#9932cc]
    <nav className="navbar sticky top-0 z-50 bg-purple-500  pt-1 pb-1 ">
      <div className="navbar-container flex  justify-between items-center m-1 md:m-3 max-sm:h-[40px]">
        {/* Logo */}
        <div className="flex-shrink-0 sm:text-[20px]">
          <Link
            to="/"
            // className="navbar-logo text-[22px] sm:text-[24px] md:text-[30px] lg:text-[30px] text-white font-bold sm:pl-4 "
          >
            {/* OurMicroLife */}
            <img
              src={require("../../images/macrolifelogo3.png")}
              // src={require("../../images/ourmacrolife-logo2bg-black.jpg")}
              alt="logo"
              className="w-24 h-16"
            />
          </Link>
        </div>
        <p className=" apply-btn text-white font-bold text-[17px] ml-5 hidden sm:inline mr-5">
          OurMicroLife
        </p>
        <div className="card flex justify-start items-center text-white font-bold text-2xl ml-5 cursor-pointer relative">
          <a
            href="https://vendor.ourmicrolife.com/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center   "
          >
            <div>
              <FaShop size={26} className="font-bold" />
            </div>

            <div className="text-[24px] font-semibold text-white hidden lg:block ml-2 ">
              Become Seller
            </div>
          </a>
        </div>
        {/* Search Bar Container */}
        {/* --------------------------------------------- */}
        <div className="flex-grow  flex justify-center items-center hidden lg:block lg:ml-10 ">
          {/* Full Search Bar for larger screens */}
          <div className="bg-white sm:flex items-center border-2 border-gray-300 rounded-2xl p-2 w-full max-w-xl md:max-w-xl lg:max-w-2xl xl:max-w-3xl ">
            <input
              type="text"
              value={serchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              placeholder="Searching Products..."
              className="search-input text-black p-2 ml-5 border-none w-full outline-none text-sm sm:text-base"
            />
            <FaSearch
              className="mr-5 text-purple-500 "
              onClick={() => navigate("/product-search")}
            />
          </div>

          {/* Search Icon for mobile view */}
          <div className="sm:hidden flex items-center">
            <button
              onClick={() => setShowSearch(!showSearch)}
              className="text-purple-500 p-2 hidden sm:inline "
            >
              <FaSearch size={24} />
            </button>
          </div>
        </div>

        <div className="returnCard flex flex-col mr-18 ml-8 cursor-pointer  hidden sm:inline relative">
          <Link to="/account/myorders">
            <p className="text-white cursor-pointer">Returns</p>
            <p className="text-white font-semibold cursor-pointer">& Orders </p>
          </Link>
        </div>
        {/* Icons */}
        <div className="flex-shrink-0 flex items-center space-x-3 lg:pr-5 sm:ml-5 max-sm:space-x-0">
          <div className="relative group ">
            <div className=" flex items-center space-x-2 text-white rounded-lg p-2 hover:bg-white hover:text-purple-700 text-sm sm:text-base">
              <MdInstallMobile size={24} className="cursor-pointer" />
            </div>
            {/* Dropdown */}
            <div className="absolute top-9 z-50 left-0 mt-1 lg:w-55 bg-white shadow-lg rounded-lg py-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 invisible group-hover:visible ">
              <a
                href="https://play.google.com/store"
                target="_blank"
                rel="noreferrer"
                className="flex items-center px-4 py-2 hover:bg-purple-50 text-gray-800  "
              >
                <IoLogoAndroid className="mr-2 text-green-500" size={20} />
                <p className="hidden sm:inline ">Android </p>
              </a>
              <a
                href="https://www.apple.com/app-store/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center px-4 py-2 hover:bg-purple-50 text-gray-800"
              >
                <IoIosAppstore className="mr-2 text-blue-500" size={20} />
                <p className="hidden sm:inline ">ios </p>
              </a>
            </div>
          </div>
          <div className="relative group">
            <Link
              to={token ? "/account/account_details" : "/login-user"}
              className=" flex items-center space-x-2 text-white p-2 rounded-lg hover:bg-white hover:text-purple-700 text-sm sm:text-base "
            >
              <FaUser size={20} className="" />
              <span className="hidden md:inline ">{token ? "" : "Login"}</span>
            </Link>

            {/* Dropdown */}
            {token && (
              <div
                className="absolute top-full right-0 mt-0 w-40 bg-white shadow-lg rounded-lg py-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 invisible group-hover:visible  "
                style={{ zIndex: 9999999999 }}
              >
                <Link
                  to="/account/account_details"
                  className="block px-4 py-2 hover:bg-purple-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiUser className=" text-blue-500  text-xl" />
                  <span>Profile</span>
                </Link>
                <Link
                  to="/cart"
                  className="block px-4 py-2 hover:bg-purple-100 text-gray-800 flex items-center space-x-2  "
                >
                  <FiShoppingCart className="text-green-500 text-xl" />
                  <span>Cart</span>

                  {/* The block that will appear on hover */}
                </Link>
                <Link
                  to="/account/myorders"
                  className="block px-4 py-2 hover:bg-purple-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiPackage className="text-xl text-yellow-500" />
                  <span>Orders</span>
                </Link>
                <Link
                  to="/account/wishlist-products"
                  className="block px-4 py-2 hover:bg-purple-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiHeart className="text-xl text-red-500" />
                  <span>Wishlist</span>
                </Link>
                <Link
                  to="/wallet"
                  className="block px-4 py-2 hover:bg-purple-100 text-gray-800 flex items-center space-x-2"
                >
                  <GiTwoCoins className="text-xl text-yellow-400" />

                  <span>Wallet Coins</span>
                </Link>

                <button
                  onClick={() => {
                    localStorage.removeItem("token");
                    // window.location.reload();
                    navigate("/");
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-purple-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiLogOut className="text-xl text-gray-500 " />
                  <span>Sign Out</span>
                </button>
              </div>
            )}
          </div>

          <Link
            to="/cart"
            className="flex items-center space-x-2 p-2 text-white rounded-lg hover:bg-white hover:text-purple-700 relative block group"
          >
            <IoCartSharp size={24} />
            {/* <span className="hidden md:inline text-sm sm:text-base">Cart</span> */}

            {/* Badge for total quantity */}
            {cartItems.length > 0 ? (
              <div className="absolute w-[200px]  bg-white top-10 right-0 hidden group-hover:block p-2 shadow-lg rounded-md border border-gray-300 overflow-hidden">
                {/* Items List */}
                <div className="overflow-y-auto max-h-[400px]">
                  {cartItems.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 border-b pb-2  last:border-none last:mb-0 hover:bg-purple-100"
                      onClick={() => navigate(`/products/${item.id}`)}
                    >
                      <img
                        src={`https://ourmicrolife.com/ourmicrolife/storage/app/public/${item.featured_image}`}
                        alt={item.name}
                        className="w-12 h-12 pl-1 pt-1 object-cover mr-3 rounded-md"
                      />
                      <div>
                        <p className="text-sm text-gray-800 font-semibold">
                          {item.name}
                        </p>
                        <p className="text-sm text-gray-600">
                          Color: {item.pivot.color}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* See More Button */}
                <div className="mt-4 text-center">
                  <button
                    className="w-full py-2 bg-purple-600 text-white text-sm rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    onClick={() => navigate("/cart")} // Redirect to the cart page
                  >
                    See More
                  </button>
                </div>
              </div>
            ) : null}

            {totalQuantity > 0 && (
              <span className="absolute top-0 right-0 inline-block h-5 w-5 rounded-full bg-red-600 text-white text-xs font-bold flex items-center justify-center">
                {totalQuantity}
              </span>
            )}
          </Link>
          <Link
            to="/wallet"
            className="flex items-center space-x-2 p-2 text-white rounded-lg hover:bg-white hover:text-purple-700 relative"
          >
            <IoWallet size={24} />
            {/* <span className="hidden md:inline text-sm sm:text-base">Cart</span> */}

            {/* Badge for total quantity */}

            {/* {totalQuantity >= 0 && (
              <span className="absolute top-0 right-0 inline-block h-5 w-5 rounded-full bg-red-600 text-white text-xs font-bold flex items-center justify-center">
             
              </span>
            )} */}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
