import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaCaretRight } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { IoCalendarClear } from "react-icons/io5";
import { FaUndoAlt, FaTimes } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { PiArrowElbowUpLeftBold } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getOrders, userAddresses } from "../../../redux/action";

import axios from "axios";

const OrderDetails = () => {
  const { id } = useParams(); // Get the id from the URL
  const orderId = parseInt(id);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [userOrderAddress, SetOrderAddress] = useState([]);
  const [fulladdress, setFullAddress] = useState("");
  const [nearAddress, setNearbyAddress] = useState("");
  const navigate = useNavigate();
  const [showCancelPopup , setShowCancelPopup] = useState(false);
  const steps = [
    { label: "Order Confirmed", date: "Tue, 3rd Dec", key: "confirmed" },
    { label: "placed", date: "Thu, 5th Dec", key: "placed" },
    { label: "Out for delivery", date: "Sat, 7th Dec", key: "outForDelivery" },
    { label: "Delivery", date: "Expected by Sun, 8th", key: "delivered" },
  ];

  const location = useLocation();
  const { productData, orderDetail } = location.state;
  console.log("receiver data is ", productData, orderDetail);

  const address = useSelector((state) => state.userAddresses);
  const dispatch = useDispatch();
  console.log("the redux address is is is address", address);

  console.log("the redux address is is is address", address);
  const orderData = orderDetail.find((item) => item.id === orderId);
  console.log("found product in order detail", orderData);

  // const orderAddress = address?.addresses?.filter(
  //   (item) => item.id === orderData.shipping_address_id
  // );

  // console.log("found address", orderAddress);
  // const addresses=address.addresses.find((item) => item.id === product.
  // shipping_address_id)
  // console.log(" found address",addresses);

  useEffect(() => {
    dispatch(userAddresses());
    dispatch(getOrders());
    fetchFirstCampaign();
    fetchSecondCampaign();
  }, [dispatch]);
  useEffect(() => {
    if (orderData && address?.addresses) {
      const orderAddress = address.addresses.filter(
        (item) => item.id === orderData.shipping_address_id
      );
      SetOrderAddress(orderAddress[0]);
      setFullAddress(orderAddress[0].full_address.slice(0, 19));
      setNearbyAddress(orderAddress[0].near_by_address.slice(0, 19));
      console.log("found address", orderAddress[0]);
    }
  }, [orderData, address]);

  const getStepClass = (stepKey) => {
    const stepOrder = steps.map((step) => step.key);
    const currentIndex = stepOrder.indexOf(orderData.status);
    const stepIndex = stepOrder.indexOf(stepKey);

    if (stepIndex <= currentIndex) return "bg-green-500 text-white";
    if (stepIndex === currentIndex + 1) return "bg-gray-300 text-gray-500";
    return "bg-gray-100 text-gray-400";
  };

  // If no product is found, show a message
  if (!productData) {
    return <div className="p-6">Order not found</div>;
  }
  const fetchFirstCampaign = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/campaign/page/order/location/1`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setFirst(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 1", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };
  const fetchSecondCampaign = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/campaign/page/order/location/2`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setSecond(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 2", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };
  const handleProductDetail = (id) => {
    navigate(`/products/${id}`);
  };

  const calculateDiscount = (salePrice, oldPrice) => {
    let saleP = +salePrice;
    let oldP = +oldPrice;
  
    if (oldP > 0) {
      return Math.round(oldP - saleP); // Calculate discount percentage
    }
    return 0;
  };

  const handleCancelClick = () => {
    setShowCancelPopup(true);
  };

  // Function to confirm cancellation
  const confirmCancel = () => {
    // cancel ordere api
    setShowCancelPopup(false);
  
  };

  // Function to close the popup
  const closePopup = () => {
    setShowCancelPopup(false);
  };
  return (
    <div className="lg:p-5 sm:p-5 bg-[#E8F1F9]">
      <div className="flex flex-row ">
        <div className="w-full">
          {/* <h1 className="flex justify-center text-3xl font-bold lg:mb-14 lg:mt-5">
            Order Details
          </h1> */}
          <div className="flex flex-col justify-center items-center w-full ">
            <div className="flex pl-4 bg-white text-black rounded-sm py-4  mb-1 shadow-sm w-full">
              <div className="border-r-[2px] border-gray-200 max-sm:w-[60%]">
                <div className="flex  mt-1 max-sm:flex-col">
                  <div className="lg:mr-10">
                    <p className="text-gray-500">Customer Details</p>
                    <p className="font-semibold">
                      {userOrderAddress.full_name}
                    </p>
                  </div>
                  <div className="mr-8">
                    <p className="text-gray-500">Contact Number</p>
                    <p className="font-semibold">
                      {userOrderAddress.mobile_number}
                    </p>
                  </div>

                  <div>
                    <div className="pr-5">
                      <p className="text-gray-500">Alt. Contact</p>

                      {userOrderAddress.alternate_mobile_number ? (
                        <p className="font-semibold">
                          {userOrderAddress.alternate_mobile_number}
                        </p>
                      ) : (
                        <p>...</p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Order related */}
                <div className="flex text-gray-500 text-sm font-medium mt-3 lg:mr-10 max-sm:flex-col">
                  <div className="lg:mr-10">
                    <span className="flex">
                      <IoCalendarClear className="mt-1 mr-2" />
                      {format(new Date(orderData.created_at), "MMMM dd, yyyy")}
                    </span>
                    <span className="ml-6">
                      Shipping Method: Standart Delivery
                    </span>
                  </div>
                  <div className="ml-4">
                    <span className="flex">
                      <FiShoppingCart className="mt-1 mr-2" />
                      Order Id {orderData.order_id}
                    </span>
                    <span className="ml-6">{}</span>
                  </div>
                  <div className="ml-4">
                    <div className="flex pt-5">
                      <span className="flex text-yellow-400">
                        <FaCaretRight className="mt-1 mr-1" />
                        {orderData.payment_type}
                      </span>
                      <span className="text-[#34C759] ml-2 mr-2">
                        ₹ {orderData.total_price}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Address */}
              <div className="flex flex-col mx-4 max-sm:w-[40%]">
                <div className="flex mb-4 max-sm:flex-col">
                  <div className="mr-6">
                    <p className="text-gray-500">Address</p>
                    <p className="font-semibold">{fulladdress}</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Nearby Landmark</p>
                    <p className="font-semibold">{nearAddress}</p>
                  </div>
                </div>
                <div className="flex max-sm:flex-col">
                  <div className="mr-6">
                    <p className="text-gray-500">City</p>
                    <p className="font-semibold">{userOrderAddress.city}</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Pincode</p>
                    <p className="font-semibold">{userOrderAddress.pin_code}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="delivery-instruction flex justify-center items-center w-full bg-red-100 rounded-sm py-1 px-8 mt-1 text-center gap-2">
              <TbTruckDelivery className="text-bold " />
              <p>
                "Delivery Instructions : Kindly call us when you arive to ensure
                a smooth and timely handover the delivery"
              </p>
            </div>
          </div>

          <div className="border p-4 mb-2 rounded-sm shadow-sm  bg-white mt-2">
            <div className="flex justify-between flex-col lg:flex-row">
              <div
                className="flex gap-6 max-sm:flex-col"
                onClick={() => handleProductDetail(orderData.product_id)}
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${orderData.image_path}`}
                  alt={orderData.product_name}
                  className="w-32 h-32 object-cover rounded-lg"
                />
                <div className="pl-2">
                  <h2 className="font-bold text-lg">
                    {orderData.product_name}
                  </h2>
                  <p className="text-gray-500">Color: {orderData.color}</p>
                  <p className="text-gray-500">size: {orderData.size}</p>
                  <p className="text-gray-500">
                    Price: ₹ {orderData.total_price}
                  </p>
                </div>
              </div>
              {/* progress bar  */}
              <div className="flex items-center m-4 max-sm:mx-0 max-sm:flex-wrap">
                {steps.map((step, index) => (
                  <div
                    key={step.key}
                    className="flex flex-col items-center sm:items-start mb-4 sm:mb-0"
                  >
                    {/* Step Label */}
                    <div className="text-center sm:text-left">
                      <p
                        className={`font-semibold text-xs sm:text-sm md:text-base mb-2 ${
                          getStepClass(step.key).includes("green")
                            ? "text-green-500"
                            : "text-gray-500"
                        }`}
                      >
                        {step.label}
                      </p>
                    </div>

                    {/* Step Icon and Line */}
                    <div className="flex items-center">
                      {/* Step Icon */}
                      <div
                        className={`w-5 h-5 sm:w-6 sm:h-6 rounded-full border-2 ${getStepClass(
                          step.key
                        )} flex items-center justify-center`}
                      >
                        {index ===
                        steps.map((s) => s.key).indexOf(orderData.status) ? (
                          <div className="w-3 h-3 bg-green-500"></div>
                        ) : null}
                      </div>

                      {/* Horizontal Line */}
                      {index < steps.length - 1 && (
                        <div
                          className={`h-0.5 w-16 sm:24 lg:w-28 ${
                            getStepClass(step.key).includes("green")
                              ? "bg-green-500"
                              : "bg-gray-300"
                          }`}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="max-sm:pl-2">
                <p className="text-gray-500">Order Id:{orderData.order_id}</p>
                <p className="text-gray-500">Status:{orderData.status}</p>
                <p className="text-gray-500">Quantity:{orderData.quantity}</p>
                {/* <p>Order Year: {orderData.created_at}</p> */}
                <div className="mt-2">
                  {orderData.status === "delivered" ? (
                    <button className="return-button flex items-center gap-2  text-blue-500">
                      <FaUndoAlt /> Return
                    </button>
                  ) : (
                    <button
                      className="cancel-button flex items-center gap-2 text-blue-500"
                      onClick={handleCancelClick}
                    >
                      <MdOutlineCancel /> Cancel
                    </button>
                  )}
                  {showCancelPopup && (
                    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-60 ">
                      <div className="bg-white rounded-lg shadow-lg p-6 w-[700px] text-center">
                        <div className="flex justify-between items-center border-b pb-2 mb-4">
                          <h2 className="text-lg font-semibold">
                            Confirm Cancellation
                          </h2>
                          <button
                            onClick={closePopup}
                            className=" text-gray-400 hover:text-gray-600 focus:outline-none text-[30px]"
                          >
                            ×
                          </button>
                        </div>
                        <div className=" flex text-center items-center justify-between bg-purple-50 p-2">
                          <p className="text-green-600 flex items-center justify-center mb-4">
                            <i className="mr-2">💸</i> You saved ₹{calculateDiscount(orderData.total_price,orderData.old_price)} on this
                            product!
                          </p>

                          <img
                            src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${orderData.image_path}`}
                            alt={orderData.product_name}
                            className="w-32 h-32 object-cover rounded-lg"
                          />
                        </div>
                        <p className="text-sm text-gray-600 mb-6 mt-2">
                          If you cancel now, you may not be able to avail this
                          deal again. Do you still want to cancel?
                        </p>
                        <div className="flex gap-5">
                          <button
                            // onClick={onCancel}
                            className=" text-red-600 px-4 py-2 rounded hover:shadow-lg border border-2 border-red-600"
                          >
                            Cancel Order
                          </button>
                          <button
                            onClick={closePopup}
                            className=" text-gray-700 px-4 py-2 border border-2 rounded  focus:outline-none focus:ring-2 focus:ring-gray-200"
                          >
                            Don't cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[230px] bg-white shadow-lg">
            <img
              src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${second.image}`}
              alt=""
              className="w-full  h-[230px]"
            />
          </div>
        </div>

        <div className="flex flex-col w-[450px] h-[594px] bg-white shadow-sm rounded-sm hidden md:inline  ml-3 ">
          <div>
            <img
              src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${first.image}`}
              alt=""
              className="w-[450px] h-[500px] p-2"
            />
          </div>

          <div className="w-full flex justify-center items-center align-center gap-2 mt-2 px-2">
            <button
              className="w-full flex items-center justify-center bg-blue-500 text-white py-2 h-[80px] rounded-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 animated-bg "
              onClick={() => navigate("")}
            >
              <PiArrowElbowUpLeftBold className="mr-2 text-xl " />
              <p className="text-2xl font-semibold">Continue Shopping</p>
            </button>
          </div>

          <style jsx>
            {`
              .animated-bg {
                animation: colorChange 8s infinite,
                  fadeInOut 2s infinite alternate;
              }

              @keyframes colorChange {
                0% {
                  background-color: #3498db; /* Blue */
                }
                25% {
                  background-color: #e74c3c; /* Red */
                }
                50% {
                  background-color: #2ecc71; /* Green */
                }
                75% {
                  background-color: #f1c40f; /* Yellow */
                }
                100% {
                  background-color: #3498db; /* Blue (loop back) */
                }
              }

              @keyframes fadeInOut {
                0% {
                  opacity: 1;
                }
                50% {
                  opacity: 0.5;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
          </style>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
