import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-r from-purple-50 to-purple-100   mx-auto py-12">
      <div className="container mx-auto px-8 py-12">
        <h1 className="text-4xl font-extrabold text-center text-purple-800 mb-12">
          Privacy Policy
        </h1>

        <div className="space-y-10 text-gray-800">
          {/* Introduction */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Introduction
            </h2>
            <p className="text-lg">
              Welcome to <span className="font-semibold text-purple-600">OurMicroLife</span>. We value your privacy and are
              committed to safeguarding your personal data. This privacy policy outlines how we collect, use, and protect
              your information. By using our services, you agree to the practices described in this policy.
            </p>
          </section>

          {/* Information Collection */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Information We Collect
            </h2>
            <p className="text-lg">
              We collect personal information that you provide directly to us, such as:
            </p>
            <ul className="list-disc pl-5 space-y-3 text-lg">
              <li>Your name, email address, and contact details</li>
              <li>Shipping and billing information</li>
              <li>Payment details (processed securely via our payment providers)</li>
              <li>Order history and preferences</li>
              <li>Other data you provide through customer support or surveys</li>
            </ul>
          </section>

          {/* How We Use Your Information */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              How We Use Your Information
            </h2>
            <p className="text-lg">
              The information we collect is used to improve your experience with our platform. We use your data to:
            </p>
            <ul className="list-disc pl-5 space-y-3 text-lg">
              <li>Process and fulfill your orders</li>
              <li>Provide responsive customer service</li>
              <li>Send you promotional offers and updates</li>
              <li>Enhance our website and services</li>
              <li>Comply with legal obligations and regulations</li>
              <li>Secure our platform against fraudulent activity</li>
            </ul>
          </section>

          {/* Data Security */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Data Security
            </h2>
            <p className="text-lg">
              We use advanced security protocols to protect your personal information from unauthorized access. Your data
              is stored on secure servers, and we employ encryption techniques to protect sensitive information. While we
              take every measure to protect your privacy, no online transmission method is 100% secure.
            </p>
          </section>

          {/* Cookies */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Cookies and Tracking
            </h2>
            <p className="text-lg">
              Our website uses cookies to provide a better browsing experience. Cookies help us remember your preferences
              and improve site functionality. You can manage your cookie preferences through your browser settings, but
              disabling cookies may affect the performance of some features on our website.
            </p>
          </section>

          {/* Third-Party Services */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Third-Party Services
            </h2>
            <p className="text-lg">
              We may share your information with third-party partners such as payment processors, shipping companies, and
              analytics services. These partners are bound by contracts to use your data only for specific purposes and
              in compliance with applicable laws.
            </p>
          </section>

          {/* Your Rights */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Your Rights
            </h2>
            <p className="text-lg">
              You have the right to access, update, and delete your personal data at any time. To exercise your rights,
              simply log into your account or contact our customer support team. You can also opt-out of marketing
              communications by following the unsubscribe instructions in our emails.
            </p>
          </section>

          {/* Updates to Policy */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Updates to This Policy
            </h2>
            <p className="text-lg">
              We may update this privacy policy to reflect changes in our services or to comply with new legal
              requirements. When we make significant updates, we will notify you through our website or other means.
            </p>
          </section>

          {/* Contact Us */}
          <section className="rounded-lg shadow-sm bg-white p-6">
            <h2 className="text-3xl font-semibold text-purple-600 text-center mb-4">
              Contact Us
            </h2>
            <p className="text-lg">
              If you have any questions regarding this privacy policy or wish to exercise your rights, please reach out to
              us:
            </p>
            <ul className="list-disc pl-5 space-y-3 text-lg">
              <li>Email: <a href="mailto:support@OurMicroLife.com" className="text-purple-600">support@OurMicroLife.com</a></li>
              <li>Phone: <span className="text-purple-600">+91 9955661643</span></li>
              <li>Address: 1234 Main St, City, Country</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
