import axios from "axios";
import {
  GET_PRODUCTS,
  USER_ADDRESSES,
  SET_USER_PROFILE,
  SET_USER_CART_ITEMS,
  SET_USER_CART,
  SET_ORDERS,
  UPDATE_TOTAL_QUANTITY,
} from "./reducer";

const userId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// Fetch Products
export const addproduct = () => async (dispatch) => {
  try {
    const product = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}get`
    );
    dispatch({
      type: GET_PRODUCTS,
      payload: product.data,
    });
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

// Fetch User Addresses
export const userAddresses = () => async (dispatch) => {
  try {
    const addresses = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/shipping-addresses/user_id_${userId}`
    );
    dispatch({
      type: USER_ADDRESSES,
      payload: addresses.data,
    });
  } catch (error) {
    console.error("Error fetching user addresses:", error);
  }
};

// Fetch User Profile
export const fetchUserProfile = () => async (dispatch) => {
  if (!userId || !token) {
    console.error("User ID or token is missing");
    return; // Prevent the request if userId or token is missing
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/profile/${userId}`,
      config
    );
    dispatch({
      type: SET_USER_PROFILE,
      payload: response.data,
    });
    console.log("Profile data:", response.data);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized: Invalid token or session expired.");
      // Optional: dispatch a logout or token refresh action
    } else {
      console.error("Error fetching user profile:", error);
    }
  }
};

// Fetch Cart Items
export const getCartItems = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://uvfolderking.com/suresop/api/cart/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Use the token to authenticate the request
        },
      }
    );
    console.log("Cart Items Response:", response.data.cart.products);
    const cartItems = response.data.cart.products;
    const totalQuantity = cartItems.reduce(
      (total, item) => total + item.pivot.quantity,
      0
    );
    console.log("Total Cart Quantity:", totalQuantity);

    dispatch({
      type: SET_USER_CART,
      payload: { cartItems, totalQuantity },
    });
  } catch (error) {
    console.error("Error fetching cart items:", error);
  }
};

export const addToCart = (cartItems, totalQuantity) => ({
  type: SET_USER_CART_ITEMS,
  payload: {
    cartItems,
    totalQuantity,
  },
});

export const getOrders = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}orders/user_id_${userId}`
    );
    console.log("order Items Response:", response.data.orders);

    dispatch({
      type: SET_ORDERS,
      payload: response.data.orders,
    });
  } catch (error) {
    console.error("Error fetching cart items:", error);
  }
};

export const updateTotalQuantity = (totalQuantity) => ({
  type: "UPDATE_TOTAL_QUANTITY",
  payload: totalQuantity,
});
