import React, { useState, useEffect } from 'react';

const RefundAndCancellation = () => {

  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');
  const [isRefundRequested, setIsRefundRequested] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleRefundRequest = () => {
    if (!orderId || !reason) {
      setMessage('Please fill in all fields to proceed with the request.');
      return;
    }
    setLoading(true);
    // Simulate an API call to request a refund
    setTimeout(() => {
      setLoading(false);
      setIsRefundRequested(true);
      setMessage('Your refund request has been successfully submitted! We will process it soon.');
    }, 2000);
  };

  const handleCancelOrder = () => {
    if (!orderId || !reason) {
      setMessage('Please fill in all fields to proceed with the cancellation.');
      return;
    }
    setLoading(true);
    // Simulate an API call to cancel the order
    setTimeout(() => {
      setLoading(false);
      setIsCancelled(true);
      setMessage('Your order has been successfully cancelled! We will send a confirmation email shortly.');
    }, 2000);
  };

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-r from-purple-50 to-purple-100  mx-auto p-6 bg-white ">
      <h2 className="text-3xl font-semibold mb-6 text-center text-purple-600">Refund & Cancellation</h2>
      
      <p className="text-gray-700 mb-6">
        We understand that sometimes things don't go as planned, and you may need to either request a refund or cancel your order. To make this process easier for you, please fill out the form below with the required details.
      </p>
      
      <p className="text-gray-700 mb-6">
        If you’re requesting a refund, please provide a detailed reason why you would like to return the product. This helps us process your request more efficiently. Similarly, if you wish to cancel the order, let us know the reason so that we can assist you in the best possible way.
      </p>

      <div className="mb-6">
        <label className="block text-gray-700" htmlFor="orderId">
          Order ID
        </label>
        <input
          type="text"
          id="orderId"
          value={orderId}
          onChange={handleOrderIdChange}
          className="w-full p-3 border border-gray-300 rounded-md mt-2 focus:ring-2 focus:ring-purple-500 focus:outline-none"
          placeholder="Enter your order ID"
        />
      </div>
      
      <div className="mb-6">
        <label className="block text-gray-700" htmlFor="reason">
          Reason for Refund or Cancellation
        </label>
        <textarea
          id="reason"
          value={reason}
          onChange={handleReasonChange}
          className="w-full p-3 border border-gray-300 rounded-md mt-2 focus:ring-2 focus:ring-purple-500 focus:outline-none"
          placeholder="Provide your reason for requesting a refund or cancellation"
          rows="6"
        ></textarea>
      </div>

      <p className="text-gray-700 mb-6">
        Once you submit the form, your request will be processed, and we will get back to you as soon as possible. If the refund request is accepted, the amount will be refunded to your original payment method, and you will be notified. Please allow a few business days for the process to complete.
      </p>

      <div className="flex justify-center space-x-4 mb-6">
        <button
          onClick={handleRefundRequest}
          className="w-[300px] bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition-all disabled:opacity-50"
          disabled={loading || isRefundRequested || isCancelled}
        >
          {loading ? 'Processing...' : 'Request Refund'}
        </button>
        <button
          onClick={handleCancelOrder}
          className="w-[300px] bg-red-500 text-white py-3 rounded-md hover:bg-red-600 transition-all disabled:opacity-50"
          disabled={loading || isRefundRequested || isCancelled}
        >
          {loading ? 'Processing...' : 'Cancel Order'}
        </button>
      </div>

      <p className="text-gray-700 mb-6">
        Refunds: Depending on the reason for your refund request, we may ask for additional information or evidence to verify the claim. If you’re returning a product, it should be in its original condition and packaging. Once the refund is processed, you’ll receive an email notification.
      </p>

      <p className="text-gray-700 mb-6">
        Cancellations: We will cancel your order as quickly as possible. However, if the order has already been processed or shipped, cancellation might not be possible. In such cases, we will guide you on how to return the item and proceed with the refund process.
      </p>

      {message && <p className="text-center text-gray-700 mb-4">{message}</p>}

      {(isRefundRequested || isCancelled) && (
        <div className="mt-6 text-center">
          <p className="text-green-500 font-semibold">
            {isRefundRequested
              ? 'Your refund request has been successfully submitted. We will notify you once it’s processed.'
              : 'Your order has been successfully cancelled! A confirmation email will be sent shortly.'}
          </p>
        </div>
      )}
    </div>
  );
};

export default RefundAndCancellation;
