import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container bg-gradient-to-r from-purple-50 to-purple-100 mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-center text-purple-800 mb-12">
        About Us
      </h1>

      <section className="text-center mb-16">
        <p className="text-xl text-gray-700 max-w-3xl mx-auto">
          Welcome to <span className="text-purple-600 font-semibold">OurMicroLife</span>, an innovative multi-vendor
          eCommerce platform where entrepreneurs, vendors, and affiliates come together to create a thriving marketplace.
          We combine the power of independent vendors with the benefits of a multilevel marketing (MLM) structure, enabling
          businesses and individuals alike to maximize growth, reach, and earnings.
        </p>
      </section>

      <section className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-purple-800 mb-4">Our Mission</h2>
        <p className="text-lg text-gray-600 max-w-4xl mx-auto">
          At  <span className="text-purple-600 font-semibold">OurMicroLife</span>, our mission is to empower small businesses through a
          robust, multi-vendor eCommerce platform, while also fostering a dynamic and rewarding community with our multilevel
          marketing (MLM) system. We aim to give our vendors and affiliates the tools they need to succeed, grow their
          businesses, and earn passive income. Our platform is built to help both vendors and individuals achieve long-term
          success while delivering a unique and exceptional shopping experience to customers.
        </p>
      </section>

      <section className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-purple-800 mb-4">What We Offer</h2>
        <p className="text-lg text-gray-600 max-w-4xl mx-auto mb-8">
          OurMicroLife is more than just an eCommerce platform; it's a community-driven marketplace that provides vendors and
          affiliates with the tools to thrive. Whether you're a customer looking for unique products, a vendor seeking growth,
          or an affiliate wanting to maximize earnings, OurMicroLife has something for everyone.
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 text-left max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Diverse Product Range</h3>
            <p className="text-gray-600">Discover a curated selection of products, from handmade crafts to high-tech gadgets.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Secure & Transparent Shopping</h3>
            <p className="text-gray-600">We prioritize your privacy and security, ensuring safe transactions and data protection.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Empowering MLM System</h3>
            <p className="text-gray-600">Join our multilevel marketing program to earn commissions by promoting products and recruiting affiliates.</p>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 py-16 mb-16">
        <h2 className="text-3xl font-semibold text-purple-800 text-center mb-6">For Our Vendors</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto mb-8 text-center">
          OurMicroLife offers a comprehensive platform for vendors to showcase and sell their products. Whether you’re a
          small artisan or an established business, our platform supports your growth with a suite of tools and features
          designed to help you succeed.
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 text-left max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Comprehensive Seller Dashboard</h3>
            <p className="text-gray-600">Track sales, manage inventory, and view performance analytics with ease.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Integrated MLM System</h3>
            <p className="text-gray-600">Grow your sales and earn additional commissions by participating in the MLM program.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Marketing and Promotional Tools</h3>
            <p className="text-gray-600">Use built-in tools to run promotions, discounts, and feature products for more visibility.</p>
          </div>
        </div>
      </section>

      <section className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-purple-800 mb-4">For Our Affiliates</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          At OurMicroLife, we provide a unique opportunity for affiliates to earn commissions through our multilevel
          marketing (MLM) program. Promote products, refer vendors and customers, and earn passive income by building your own
          team. Our platform makes it easy for you to track your performance, manage your referrals, and grow your earnings.
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 text-left max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Earn Passive Income</h3>
            <p className="text-gray-600">Promote products and earn commissions for every sale made through your referral links.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Build Your Network</h3>
            <p className="text-gray-600">Invite others to join and earn additional commissions from their sales.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-600 mb-2">Track Your Performance</h3>
            <p className="text-gray-600">Easily track your sales, commissions, and team growth with our affiliate dashboard.</p>
          </div>
        </div>
      </section>

      <section className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-purple-800 mb-4">Join Our Community</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          Whether you’re a vendor looking to sell your products, an affiliate wanting to earn passive income, or a customer
          seeking quality goods, OurMicroLife is the place to be. Explore, connect, and grow with us today.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
