import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import animationData from "../animations/welcomeanimation.json"; // Replace with your animation JSON file
import congratulations from "../animations/congratulations.json"; // Replace with your congratulations animation JSON file

const Welcome = () => {
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Set timeout to navigate after 3 seconds
    const timer = setTimeout(() => {
      navigate("/login-user"); // Navigate to /login-user
    }, 3000);
    // Cleanup timeout on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-row items-center justify-center pt-20  pb-20 bg-gradient-to-r from-blue-200 via-purple-300 to-pink-300">
      {/* First Animation */}
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: 400, width: 400 }}
      />

      {/* Congratulations message */}
      <div className="flex flex-col items-center justify-center  p-6 bg-white  max-w-lg mx-auto">
        <Lottie
          animationData={congratulations}
          loop={true}
          style={{ height: 200, width: 300 }}
        />
        <p className="text-4xl font-bold text-center mt-6 text-indigo-700 leading-tight">
          🎉 Congratulations! You've officially joined the{" "}
          <span className="text-purple-500">OurMicroLife</span> family! 🎉
        </p>
      </div>

      {/* Second Animation */}
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: 400, width: 400 }}
      />
    </div>
  );
};

export default Welcome;
