import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { addproduct } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ImageGalleryTwo = () => {
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [second, setSecond] = useState([]);
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  // Shuffle and get random products
  const getRandomProducts = (products) => {
    const product = [...products];
    return product.sort(() => 0.5 - Math.random()).slice(0, 4);
  };

  const randomProducts = getRandomProducts(products);

  useEffect(() => {
    dispatch(addproduct());
    setLoadingProducts(false); // Assume Redux will update quickly
  }, [dispatch]);

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/category`
      );
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoadingCategories(false);
    }
  };
  const fetchFirstCampaign = async () => {
    try {
      console.log("fetch1");
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/campaign/page/home/location/2`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setSecond(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 1", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchFirstCampaign();
  }, []);

  const getRandomCategory = (category) => {
    return category.sort(() => 0.5 - Math.random()).slice(0, 4);
  };

  const randomCategory = getRandomCategory(categories);

  return (
    <div className="container mx-auto lg:pl-10 lg:pr-10 pb-5 pt-5 shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
        {/* Column 3: See More Button */}
        <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg p-6">
          {loadingCategories ? (
            <Skeleton height={550} width={450} className="rounded-lg" />
          ) : (
            <div
              className="w-full h-auto bg-cover bg-center aspect-[466/627]  flex items-end justify-center relative"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${second.image})`,
              }}
            >
              <img
                src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${second.image}`}
                alt=""
                className="w-full h-full"
              />
              {/* <div className="text-center mb-8">
            <button
              // onClick={handleNavigation}
              className="px-6 py-3 bg-[#ff9608] font-bold text-[#faf0ca] text-lg rounded-full hover:bg-[#faf0ca] hover:text-[#ff9608] transform transition duration-500 ease-in-out hover:scale-110 animate-button"
            >
              Become Seller
            </button>
          </div> */}
            </div>
          )}
        </div>
        {/* Column 2: Top Categories */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-bold mb-4 text-gray-700">
            Top Categories
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {loadingCategories
              ? [...Array(4)].map((_, index) => (
                  <Skeleton key={index} height={200} className="rounded-lg" />
                ))
              : randomCategory.map((category) => (
                  <div
                    key={category.id}
                    className="group border bg-white rounded-lg p-2 pb-1 hover:shadow-xl transition-shadow duration-300"
                  >
                    <Link to={`/category/${category.id}`}>
                      <img
                        src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/categories/${category.image}`}
                        alt={category.categorie_name}
                        className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="text-center w-full py-2">
                        <p className="text-lg font-semibold">
                          {category.categorie_name}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>

        {/* Column 1: Top Pick */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-bold mb-4 text-gray-700">Top Pick</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {loadingCategories
              ? [...Array(4)].map((_, index) => (
                  <Skeleton key={index} height={200} className="rounded-lg" />
                ))
              : randomProducts.map((product) => (
                  <div
                    key={product.id}
                    className="group border bg-white rounded-lg p-2 pb-1 hover:shadow-xl transition-shadow duration-300"
                  >
                    <Link to={`/products/${product.id}`}>
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${product.featured_image}`}
                        alt={product.name}
                        className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="text-center w-full py-2">
                        <p className="text-lg font-semibold">{product.name}</p>
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryTwo;
