import React, { useEffect } from "react";

const ShippingAndDelivery = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container  bg-gradient-to-r from-purple-50 to-purple-100 mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8 text-purple-600">Shipping and Delivery</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Shipping and Delivery Information */}
        <div>
          <h2 className="text-2xl font-semibold mb-6 text-purple-600">Our Shipping Policy</h2>
          <p className="text-gray-700 mb-4">
            At OurMicroLife, we strive to provide fast and reliable shipping to ensure that your order reaches you as quickly as possible. We process orders within 1-2 business days and ship via trusted carriers to ensure timely delivery.
          </p>

          <h3 className="text-xl font-semibold mb-4 text-purple-600">Shipping Methods</h3>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            <li>Standard Shipping: 5-7 business days</li>
            <li>Expedited Shipping: 2-3 business days</li>
            <li>Overnight Shipping: Next business day</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-purple-600">Shipping Costs</h3>
          <p className="text-gray-700">
            Shipping costs are calculated based on your location and the shipping method selected during checkout. We offer free standard shipping on all orders over $50. For expedited or overnight shipping, additional charges may apply.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-purple-600">International Shipping</h3>
          <p className="text-gray-700">
            We currently offer international shipping to select countries. International orders may be subject to customs duties and taxes, which are the responsibility of the recipient.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-purple-600">Order Tracking</h3>
          <p className="text-gray-700">
            Once your order is shipped, you will receive a tracking number via email. You can use this number to track your order's progress on the carrier's website.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-purple-600">Delivery Time</h3>
          <p className="text-gray-700">
            Delivery times may vary depending on the shipping method selected and the destination. Please note that delivery times are estimates and may be subject to delays due to factors like weather, carrier delays, or holidays.
          </p>
        </div>

        {/* Contact Details for Shipping Inquiries */}
        <div>
          <h2 className="text-2xl font-semibold mb-6 text-purple-600">Get in Touch</h2>
          <p className="text-gray-700 mb-4">
            If you have any questions or concerns regarding your shipment, feel free to reach out to our customer support team. We are happy to assist you with any shipping inquiries!
          </p>

          <div className="space-y-4">
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-blue-600 mr-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 2H8a2 2 0 00-2 2v16a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z"
                ></path>
              </svg>
              <span>+91 9955661643</span>
            </div>

            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-blue-600 mr-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 12h4a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2v-6a2 2 0 012-2h4m4 0V4m0 8l4-4m-4 4l-4-4"
                ></path>
              </svg>
              <span>support@OurMicroLife.com</span>
            </div>

            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-blue-600 mr-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 12v6m0 0H6m6 0h6M12 6V6m0 0h6m-6 0H6"
                ></path>
              </svg>
              <span>112 Modi Towar, 98 Nehru Place, New Delhi 110019   </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingAndDelivery;
