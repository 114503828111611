import axios from "axios";
import { createContext, useState } from "react";

export const MyContext = createContext();

const MyContextProvider = (props) => {
  const API_BASE_URL = process.env.REACT_APP_NODE;

  const [search, setSearch] = useState([]);

  const getSearchItem = (item) => {
    axios
      .get(`${API_BASE_URL}user/search?q=${item}`)
      .then((response) => {
        console.log(response);
        setSearch(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(search);

  const value = {
    search,
    setSearch,
    getSearchItem,
  };
  return (
    <MyContext.Provider value={value}>{props.children}</MyContext.Provider>
  );
};

export default MyContextProvider;
