import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-r from-purple-50 to-purple-100 py-12">
      <div className="container mx-auto px-6 py-12">
        <h1 className="text-4xl font-extrabold text-center text-purple-800 mb-8">
          Terms and Conditions
        </h1>

        <div className="space-y-8 text-gray-800">
          {/* Introduction */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">
              Welcome to OurMicroLife
            </h2>
            <p className="text-lg leading-relaxed">
              Welcome to OurMicroLife. These Terms and Conditions ("Terms") govern your use of our eCommerce website and
              services. By accessing and using our platform, you agree to comply with and be bound by these Terms. Please
              take the time to review them thoroughly.
            </p>
          </section>

          {/* Acceptance of Terms */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Acceptance of Terms</h2>
            <p className="text-lg leading-relaxed">
              By accessing or using OurMicroLife's services, you agree to these Terms. If you do not agree to the Terms,
              please refrain from using our website. Your continued use of the site confirms your acceptance of these Terms.
            </p>
          </section>

          {/* Changes to Terms */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Changes to Terms</h2>
            <p className="text-lg leading-relaxed">
              We reserve the right to modify or update these Terms at any time. Any changes will be posted on this page
              and will take effect immediately. Please check back periodically to stay informed about any updates or
              revisions.
            </p>
          </section>

          {/* Use of Website */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Use of Website</h2>
            <p className="text-lg leading-relaxed">
              You are permitted to use OurMicroLife solely for lawful purposes and in accordance with these Terms. You
              agree not to engage in any activities that:
            </p>
            <ul className="list-disc pl-5 mt-4 space-y-2 text-lg leading-relaxed">
              <li>Violate any local, state, or international laws.</li>
              <li>Transmit harmful software or malicious content.</li>
              <li>Impersonate another person or entity.</li>
            </ul>
          </section>

          {/* Account Registration */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Account Registration</h2>
            <p className="text-lg leading-relaxed">
              To access certain features, you may need to register for an account. You agree to provide accurate and up-to-date
              information and to maintain the confidentiality of your account details. You are responsible for any unauthorized
              use of your account.
            </p>
          </section>

          {/* Orders and Payment */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Orders and Payments</h2>
            <p className="text-lg leading-relaxed">
              By placing an order, you agree to pay for the purchased items, taxes, shipping, and any other applicable charges. We
              accept a variety of payment methods, including credit/debit cards and secure third-party payment gateways.
            </p>
            <p className="text-lg leading-relaxed mt-4">
              We reserve the right to refuse or cancel any order due to errors in pricing, availability, or suspected fraudulent
              activity.
            </p>
          </section>

          {/* Privacy Policy */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Privacy Policy</h2>
            <p className="text-lg leading-relaxed">
              Our Privacy Policy explains how we collect, use, and protect your personal data. By using our website, you consent to
              the practices outlined in our policy.
            </p>
            <p className="text-lg leading-relaxed mt-4">
              To learn more about how we handle your information, visit our{" "}
              <a href="/privacy-policy" className="text-blue-600 hover:underline">
                Privacy Policy
              </a>.
            </p>
          </section>

          {/* Limitation of Liability */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Limitation of Liability</h2>
            <p className="text-lg leading-relaxed">
              OurMicroLife will not be liable for any indirect, incidental, special, or consequential damages arising from the
              use or inability to use our website or services, to the fullest extent permitted by law.
            </p>
          </section>

          {/* Governing Law */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Governing Law</h2>
            <p className="text-lg leading-relaxed">
              These Terms will be governed by the laws of [Your Country/State], without regard to its conflict of laws principles.
            </p>
          </section>

          {/* Contact Information */}
          <section className="rounded-lg shadow-lg bg-white p-8">
            <h2 className="text-3xl font-semibold text-purple-600 mb-4">Contact Information</h2>
            <p className="text-lg leading-relaxed">
              If you have any questions or concerns regarding these Terms, please contact us:
            </p>
            <ul className="list-disc pl-5 space-y-2 mt-4 text-lg leading-relaxed">
              <li>
                Email:{" "}
                <a href="mailto:support@OurMicroLife.com" className="text-blue-600 hover:underline">
                  support@OurMicroLife.com
                </a>
              </li>
              <li>Phone: +1 (555) 123-4567</li>
              <li>Address: 1234 Main St, City, Country</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
