import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import { addproduct } from "../../redux/action";
import { Link, Navigate } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { FaTag, FaShoppingCart } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const ProductCard = () => {
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addproduct());
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [dispatch]);

  function filterarrayProduct(products) {
    return products.map((product) => {
      const sanitizedDescription = DOMPurify.sanitize(
        product.description.slice(0, 50)
      );
      const configurations = product.prices.flatMap(
        (item) => item.configurations
      );

      const lowestPriceConfig = configurations.reduce((min, config) => {
        if (
          !min ||
          (config.sale_price &&
            parseFloat(config.sale_price) < parseFloat(min.sale_price))
        ) {
          return config;
        }
        return min;
      }, null);

      return {
        ...product,
        LowestPrice: lowestPriceConfig ? lowestPriceConfig.sale_price : null,
        OldPrice: lowestPriceConfig ? lowestPriceConfig.old_price : null,
        sanitizedDescription,
      };
    });
  }

  const productArray = filterarrayProduct(products);
  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-gray-600" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-gray-600" />;
      } else {
        return <FaRegStar key={index} className="text-gray-600" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };

  const handleProductClick = (productId) => {
    Navigate(`/products/${productId}`);
  };

  const calculateDiscount = (salePrice, oldPrice) => {
    let saleP = +salePrice;
    let oldP = +oldPrice;

    if (salePrice !== undefined && oldPrice !== undefined && oldP > saleP) {
      return Math.round(((oldP - saleP) / oldP) * 100);
    }
    return 0;
  };

  return (
    <div className="mx-auto p-2 sm:p-4 md:p-6 lg:p-10 xl:p-10 xl:pt-2">
      <div className="flex text-center justify-center items-center mb-4">
        <p className="font-bold text-xl">Featured Products</p>
      </div>

      <div className="grid grid-col-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {productArray.map((product, index) => (
          <div
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="group relative bg-white shadow-sm border-2 rounded-lg overflow-hidden transform transition-transform duration-700 ease-in-out hover:scale-105"
          >
            <Link to={`/products/${product.id}`}>
              <div className="relative">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${product.featured_image}`}
                  alt={product.name}
                  className="object-cover w-full h-48"
                />

                <img
                  src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${product.featured_image}`}
                  alt={product.name}
                  width={400}
                  height={300}
                  className="absolute inset-0 object-cover w-full h-48 opacity-0 group-hover:opacity-100 transform transition-transform duration-1000 ease-in-out hover:scale-110"
                />
                
                <div className="absolute top-1 left-1 bg-purple-500 text-white px-2 py-1 rounded-full text-xs flex items-center">
                  <FaTag className="mr-1" />{" "}
                  {calculateDiscount(product.LowestPrice, product.OldPrice)}%
                  OFF
                </div>
                <div className="absolute top-1 right-1 text-white px-2 py-1 rounded-full text-xs flex items-center">
                  <AiOutlineHeart className="w-5 h-5 inline text-red-500" />
                </div>
              </div>
              <div className="p-4">
                <p className="text-start text-lg font-semibold mb-2">
                  {product.name}
                </p>
                <p
                  className="text-sm text-gray-600 mb-2"
                  dangerouslySetInnerHTML={{
                    __html: product.sanitizedDescription,
                  }}
                ></p>
                <div className="text-sm text-gray-600 mb-2">
                  {renderStars(product.rating)}
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2 mb-2">
                    <span className="text-lg font-bold text-green-500">
                      ₹{product.LowestPrice}
                    </span>
                    <span className="text-sm text-gray-500 line-through">
                      ₹{product.OldPrice}
                    </span>
                  </div>
                  <div className="text-yellow-500">
                    <span>coins: {product.coin}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCard;
